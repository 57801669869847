import { ANALYTICS_RESOURCE, REPORT_RESOURCE, FILE_RESOURCE, CLIENT_RESOURCE } from '@/services/keys';

/**
 * @param {string} clientId
 * @param {string} reportId
 * @returns {Promise.<{file:arraybuffer,mineType:string}>}
 */
export default async function (api, clientId, reportId) {
  const partialUrl = api.createUrl({
    [ANALYTICS_RESOURCE]: null,
    [CLIENT_RESOURCE]: clientId,
    [REPORT_RESOURCE]: reportId,
    [FILE_RESOURCE]: null,
  });
  const { data, headers } = await api.get(partialUrl, undefined, { responseType: 'arraybuffer' });
  return { file: data, mineType: headers['content-type'] };
}
