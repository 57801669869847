import { api } from '../index';
import { ANALYTICS_RESOURCE, CLIENT_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import getDateUTC from '@/utils/dateTime/getDateUTC';

/**
 *
 * @param {string} clientId
 * @param {QueryParamsBuilder} params
 * @param {object} config this is only for breakdown services
 * @returns {object}>}
 */
export async function getAnalyticsV2(
  clientId,
  params = new QueryParamsBuilder(),
  config = { preventKey: 'analytics' }
) {
  const partialUrl = api.createUrl({
    [CLIENT_RESOURCE]: clientId,
    [ANALYTICS_RESOURCE]: undefined,
  });

  params.addFilter('range_start', getDateUTC(params.removeFilter('range_start')));
  params.addFilter('range_end', getDateUTC(params.removeFilter('range_end')));

  const { data } = await api.get(partialUrl, params.build(), config);

  return { data: data.data, metrics: data.metrics };
}
