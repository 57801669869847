var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-form-modal", {
    attrs: { title: "Upload Report", "modal-class": "sm:max-w-6xl" },
    on: {
      submit: _vm.submitForm,
      keypress: function ($event) {
        if (
          !$event.type.indexOf("key") &&
          _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
        )
          return null
        return _vm.submitForm.apply(null, arguments)
      },
      cancel: _vm.closeModal,
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "flex item-start py-1" },
              [
                _c(
                  "sun-button",
                  {
                    staticClass: "w-full text-xs sm:w-auto",
                    attrs: { variant: "pill", color: "blue" },
                    on: { click: _vm.downloadTemplate },
                  },
                  [_vm._v(" Download template CSV ")]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "flex flex-col items-start py-1" }, [
              _c(
                "span",
                {
                  staticClass: "w-full text-sm custom-p-1 sm:w-auto font-bold",
                },
                [_vm._v("Obligatory headers")]
              ),
              _c(
                "p",
                { staticClass: "py-1 sm:w-auto text-justify" },
                _vm._l(_vm.obligatoryHeaders, function (header) {
                  return _c(
                    "span",
                    {
                      key: header,
                      staticClass:
                        "text-xs font-semibold p-1 mr-1 mb-1 leading-tight align-middle inline-flex items-center bg-orange-500 text-white rounded-md",
                    },
                    [_vm._v(" " + _vm._s(header) + " ")]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "flex flex-col items-start py-1" }, [
              _c(
                "span",
                {
                  staticClass: "w-full text-sm custom-p-1 sm:w-auto font-bold",
                },
                [_vm._v("Optional headers")]
              ),
              _c(
                "p",
                { staticClass: "py-1 sm:w-auto text-justify" },
                _vm._l(_vm.optionalHeaders, function (header) {
                  return _c(
                    "span",
                    {
                      key: header,
                      staticClass:
                        "text-xs font-semibold p-1 mr-1 mb-1 leading-tight align-middle inline-flex items-center bg-orange-500 text-white rounded-md",
                    },
                    [_vm._v(" " + _vm._s(header) + " ")]
                  )
                }),
                0
              ),
            ]),
            _c(
              "div",
              { staticClass: "flex flex-col items-start py-1 text-sm" },
              [
                _c("span", { staticClass: "font-bold" }, [_vm._v("Rules")]),
                _c("span", { staticClass: "text-sm" }, [
                  _vm._v(" The date format should be "),
                  _c("span", { staticClass: "font-bold" }, [_vm._v("Y-m-d")]),
                  _vm._v(", for example: "),
                  _c("span", { staticClass: "font-bold" }, [
                    _vm._v(_vm._s(_vm.currentDate)),
                  ]),
                  _vm._v(". "),
                ]),
                _c("span", { staticClass: "text-sm" }, [
                  _vm._v("The date cannot be greater than or equal to today."),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "overflow-visible pb-2 py-1" },
              [
                _c(
                  "form-row",
                  [
                    _c(
                      "sun-label-group",
                      {
                        staticClass: "w-full",
                        attrs: { text: "Choose your CSV" },
                      },
                      [
                        _c("sun-file-uploader", {
                          attrs: {
                            accept: ".csv,text/csv",
                            "max-size": 104857600,
                            required: "required",
                          },
                          on: { change: _vm.setFile },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "flex flex-wrap justify-between sm:px-3" },
              [
                _c("upload-button", {
                  staticClass: "w-full text-sm custom-p-1 sm:w-auto",
                  attrs: {
                    id: "download-report-submit",
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading,
                    variant: "pill",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "text",
                      fn: function () {
                        return [_vm._v("Upload")]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _c(
                  "sun-button",
                  {
                    staticClass: "w-full text-sm custom-p-1 sm:w-auto",
                    attrs: {
                      disabled: _vm.isLoading,
                      variant: "pill",
                      color: "white",
                    },
                    on: { click: _vm.closeModal },
                  },
                  [_vm._v(" Cancel ")]
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }