import { api } from '../index';
import { ANALYTICS_RESOURCE, CLIENT_RESOURCE, TOTALS_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import getDateUTC from '@/utils/dateTime/getDateUTC';

/**
 *
 * @param {string} clientId
 * @param {QueryParamsBuilder} params
 * @param {object} config this is only for breakdown services
 * @returns {Promise.<{array:data,object:meta}>}
 */
export function getAnalyticsTotalsV2(clientId, params = new QueryParamsBuilder(), config = { preventKey: 'totals' }) {
  const partialUrl = api.createUrl({
    [CLIENT_RESOURCE]: clientId,
    [ANALYTICS_RESOURCE]: undefined,
    [TOTALS_RESOURCE]: undefined,
  });

  params.addFilter('range_start', getDateUTC(params.removeFilter('range_start')));
  params.addFilter('range_end', getDateUTC(params.removeFilter('range_end')));

  return api.get(partialUrl, params.build(), config);
}
