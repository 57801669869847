export default class Totals {
  constructor(globals = null, cost = null, custom = null) {
    this.globals = globals;
    this.cost = cost;
    this.custom = custom;
  }

  static create(entity) {
    const { globals, cost, custom } = entity;

    custom.expense = {
      amount: custom.expense,
      currency: custom.expenseCurrency,
    };

    return new Totals(globals, cost, custom);
  }

  getItems() {
    return {
      ...this.globals,
      ...this.custom,
      cost: this.cost,
    };
  }
}
