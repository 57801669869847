import { api } from '.';
import getAnalyticsReport from '@/services/shared/analytics/getAnalyticsReport';

/**
 * @param {string} clientId
 * @param {string} reportId
 * @returns {Promise.<{file:arraybuffer,mineType:string}>}
 */
export default function (clientId, reportId) {
  return getAnalyticsReport(api, clientId, reportId);
}
