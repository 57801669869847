import { api } from '.';
import { ANALYTICS_RESOURCE, CLIENT_RESOURCE, TOTALS_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import formatDateFiltersToUTC from '@/utils/formatDateFiltersToUTC';
import Totals from '@/model/shared/analytics/Totals';

/**
 *
 * @param {string} clientId
 * @param {QueryParamsBuilder} params
 * @returns {Promise.<Object>}
 */
export default async function getAnalyticsTotals(clientId, params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl({
    [ANALYTICS_RESOURCE]: null,
    [CLIENT_RESOURCE]: clientId,
    [TOTALS_RESOURCE]: null,
  });

  params.filters = {
    ...params.filters,
    ...formatDateFiltersToUTC(params.filters),
  };

  const {
    data: { metrics },
  } = await api.get(partialUrl, params.build());

  return Totals.create(metrics);
}
