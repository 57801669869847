import Column from '@/model/shared/Column';

/**
 * @name HeadersMapper
 * @class
 * @public
 */
export default class HeadersMapper {
  /**
   * @param {Array} columnsAllowed
   * @param {Array} headers
   * @param {Array} data
   * @constructor
   */
  constructor(columnsAllowed = null, headers = null, data = null) {
    this.columnsAllowed = columnsAllowed;
    this.headers = headers;
    this.data = data;
    this.productId = null;
    this.date = new Date();
    this.file = null;
    this.columnsToProcess = null;
  }

  payload() {
    const formData = new FormData();
    formData.set('productId', this.productId);
    formData.set('file', this.file);
    formData.set('date', this.date.toISOString().split('T')[0]);

    const columnsToProcess = {};
    for (const [key, value] of Object.entries(this.columnsToProcess)) {
      if (value) columnsToProcess[value.name] = key;
    }
    formData.set('columnsToProcess', JSON.stringify(columnsToProcess));

    return formData;
  }

  resetFile() {
    this.file = null;
    this.columnsAllowed = null;
    this.headers = null;
    this.data = null;
  }

  /**
   * @param {{columnsAllowed:Array, headers: Array, data: Array}} entity
   * @return {HeadersMapper}
   */
  static create(entity) {
    const columnsAllowed = entity.columnsAllowed.map(column => new Column(column));
    const headers = entity.headers.map(header => new Column(header).setClass('truncate'));

    const data = [];
    entity.data.forEach(rowArray => {
      const row = {};
      rowArray.forEach((item, index) => {
        row[headers[index].value] = item;
      });
      data.push(row);
    });

    return new HeadersMapper(columnsAllowed, headers, data);
  }
}
