import { api } from '../index';
import { ANALYTICS_RESOURCE, TEMPLATE_RESOURCE, UPLOAD_RESOURCE } from '@/services/keys';

/**
 *
 * @returns {Promise<*>}
 */
export async function uploadReportTemplate(
  params = {},
  config = { preventKey: 'upload_report_template', responseType: 'blob' }
) {
  const partialUrl = api.createUrl({
    [ANALYTICS_RESOURCE]: undefined,
    [UPLOAD_RESOURCE]: undefined,
    [TEMPLATE_RESOURCE]: undefined,
  });

  const response = await api.get(partialUrl, params, config);

  return new Blob([response.data]);
}
