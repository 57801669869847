import { thirdParty } from '..';
import { getAnalyticsByCampaignId } from './getAnalyticsByCampaignId';
import { getAnalyticsByProductId } from './getAnalyticsByProductId';
import { getAnalyticsCampaigns } from './getAnalyticsCampaigns';
import { getAnalyticsProducts } from './getAnalyticsProducts';
import { getAnalyticsV2 } from './v2/getAnalyticsV2';
import { getAnalyticsTotalsV2 } from './v2/getAnalyticsTotalsV2';
import { getAnalyticsPlatform } from './getAnalyticsPlatform';
import { getCampaignsByProductId } from './getCampaignsByProductId';
import createAnalyticsReport from './createAnalyticsReport';
import getAnalyticsReport from './getAnalyticsReport';
import getAnalyticsReportStatus from './getAnalyticsReportStatus';
import getAnalyticsTotals from './getAnalyticsTotals';
import getIngestionJobNotifications from './getIngestionJobNotifications';
import { getHeadersMapper } from './getHeadersMapper';
import { uploadCSVByProduct } from './uploadCSVByProduct';
import { getAnalyticsKpi } from './v2/getAnalyticsKpi';
import { uploadReport } from './uploadReport/uploadReport';
import { uploadReportTemplate } from './uploadReport/uploadReportTemplate';

export const api = thirdParty;

export {
  createAnalyticsReport,
  getAnalyticsByCampaignId,
  getAnalyticsByProductId,
  getAnalyticsCampaigns,
  getAnalyticsProducts,
  getAnalyticsV2,
  getAnalyticsPlatform,
  getAnalyticsReport,
  getAnalyticsReportStatus,
  getAnalyticsTotals,
  getCampaignsByProductId,
  getIngestionJobNotifications,
  getHeadersMapper,
  uploadCSVByProduct,
  getAnalyticsTotalsV2,
  getAnalyticsKpi,
  uploadReport,
  uploadReportTemplate,
};
