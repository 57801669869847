import { api } from '../index';
import { ANALYTICS_RESOURCE, CLIENT_RESOURCE, DASHBOARD_KPIS } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import AnalyticsKpis from '@/entities/ThirdParty/AnalyticsKpis';

/**
 * @param {string} clientId
 * @param {QueryParamsBuilder} params
 * @param {object} config this is only for breakdown services
 * @returns {Promise.<{array:data,object:meta}>}
 */
export async function getAnalyticsKpi(clientId, params = new QueryParamsBuilder(), config = { preventKey: 'kpi' }) {
  const partialUrl = api.createUrl({
    [CLIENT_RESOURCE]: clientId,
    [ANALYTICS_RESOURCE]: null,
    [DASHBOARD_KPIS]: null,
  });

  params.removeFilter('range_start');
  params.removeFilter('range_end');

  const { data } = await api.get(partialUrl, params.buildWithoutPage(), config);

  return AnalyticsKpis.build(data.kpis);
}
