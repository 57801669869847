import { api } from '.';
import { ANALYTICS_RESOURCE, CAMPAIGNS_RESOURCE, CLIENT_RESOURCE, PRODUCT_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import formatDateFiltersToUTC from '@/utils/formatDateFiltersToUTC';

/**
 *
 * @param {string} clientId
 * @param {string|null} productId
 * @param {QueryParamsBuilder} params
 * @returns {Promise.<Object[]>}
 */
export async function getCampaignsByProductId(clientId, productId = null, params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl({
    [ANALYTICS_RESOURCE]: undefined,
    [CLIENT_RESOURCE]: clientId,
    [CAMPAIGNS_RESOURCE]: undefined,
    [PRODUCT_RESOURCE]: productId,
  });

  params.filters = {
    ...params.filters,
    ...formatDateFiltersToUTC(params.filters),
  };

  const { data } = await api.get(partialUrl, params.build());
  return data.map(item => getStructure(item));
}

function getStructure(item) {
  item.analytic = getAnalytic(item.analytic);

  return {
    name: item.breakdown,
    ...item,
  };
}

function getAnalytic(analytic) {
  return {
    cost: analytic?.cost,
    metrics: getMetrics(analytic?.metrics),
  };
}

function getMetrics(metrics) {
  return {
    global: getMetricsGlobal(metrics?.global),
    calculated: getMetricsCalculated(metrics?.calculated),
  };
}

function getMetricsGlobal(global) {
  const model = {
    clicks: 0,
    impressions: 0,
    leads: 0,
    viewability: 0,
    views: 0,
  };

  return global ? Object.assign(model, global) : model;
}

function getMetricsCalculated(calculated) {
  const model = {
    CPMNetSales: 0,
    CPVProductImpressions: 0,
    budget: 0,
    conversions: 0,
    ctr: 0,
    daysLeft: 0,
    delivery: 0,
    deliveryNeedPerDay: 0,
    deliveryNeedsPerDayIMPS: 0,
    desiredDelivery: 0,
    discrepancy: 0,
    eCPL: 0,
    leftToDeliver: 0,
    margin: 0,
    net: 0,
    pacing: 0,
    vtr: 0,
  };

  return calculated ? Object.assign(model, calculated) : model;
}
