import { api } from '.';
import { ANALYTICS_RESOURCE, CLIENT_RESOURCE, UPLOAD_RESOURCE, HEADERS_MAPPER_RESOURCE } from '@/services/keys';
import HeadersMapper from '@/entities/ThirdParty/HeadersMapper';

/**
 *
 * @param {File} file
 * @returns {Promise.<Object>}
 */
export async function getHeadersMapper(clientId, file) {
  const partialUrl = api.createUrl({
    [ANALYTICS_RESOURCE]: undefined,
    [CLIENT_RESOURCE]: clientId,
    [UPLOAD_RESOURCE]: undefined,
    [HEADERS_MAPPER_RESOURCE]: undefined,
  });

  const fileData = new FormData();
  fileData.set('file', file);

  const { data } = await api.create(partialUrl, fileData);
  return HeadersMapper.create(data);
}
