import { api } from '.';
import { ANALYTICS_RESOURCE, CLIENT_RESOURCE, PLATFORM_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import thirdPartyEntityParser from '@/entities/ThirdParty/ThirdPartyEntityParser';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import formatDateFiltersToUTC from '@/utils/formatDateFiltersToUTC';

/**
 *
 * @param {string} clientId
 * @param {QueryParamsBuilder} params
 * @param {object} config
 * @returns {Promise.<{array:data,object:meta}>}
 */
export async function getAnalyticsPlatform(clientId, params = new QueryParamsBuilder(), config = {}) {
  const partialUrl = api.createUrl({
    [ANALYTICS_RESOURCE]: undefined,
    [CLIENT_RESOURCE]: clientId,
    [PLATFORM_RESOURCE]: undefined,
  });

  params.filters = {
    ...params.filters,
    ...formatDateFiltersToUTC(params.filters),
  };

  const { data } = await api.get(partialUrl, params.build(), config);
  return new ResponseBuilder(thirdPartyEntityParser.parse(data), data.meta).build();
}
