import { api } from '.';
import { ANALYTICS_RESOURCE, BREAKDOWN_RESOURCE, CLIENT_RESOURCE, PRODUCT_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import thirdPartyEntityParser from '@/entities/ThirdParty/ThirdPartyEntityParser';
import formatDateFiltersToUTC from '@/utils/formatDateFiltersToUTC';

/**
 * @param {string} clientId
 * @param {string|null} productId
 * @param {QueryParamsBuilder} params
 * @param {string} breakdown
 * @returns {Promise.<Object[]>}
 */
export async function getAnalyticsByProductId(
  clientId,
  productId = null,
  breakdown,
  params = new QueryParamsBuilder()
) {
  const partialUrl = api.createUrl({
    [ANALYTICS_RESOURCE]: undefined,
    [CLIENT_RESOURCE]: clientId,
    [PRODUCT_RESOURCE]: productId,
    [BREAKDOWN_RESOURCE]: breakdown,
  });

  params.filters = {
    ...params.filters,
    ...formatDateFiltersToUTC(params.filters),
  };

  const { data } = await api.get(partialUrl, params.build(), { preventCancel: true });
  return new ResponseBuilder(thirdPartyEntityParser.parse(data), data.meta).build();
}
