const CONFIG = {
  obligatoryHeaders: ['date', 'campaignName'],
  optionalsHeaders: [
    'creativeId',
    'creativeName',
    'country',
    'device',
    'impressions',
    'clicks',
    'views',
    'viewableImpressions',
    'uu',
    'engagements',
    'firstQuartile',
    'secondQuartile',
    'thirdQuartile',
    'trueViews',
    'measurableImpressions',
    'invalidImpressions',
    'videoStart',
    'leads',
    'timezone',
    'visits',
    'sessions',
  ],
};

export default CONFIG;
