import { api } from '../index';
import { ANALYTICS_RESOURCE, UPLOAD_RESOURCE } from '@/services/keys';

/**
 *
 * @returns {Promise<*>}
 */
export async function uploadReport(formData) {
  const partialUrl = api.createUrl({
    [ANALYTICS_RESOURCE]: undefined,
    [UPLOAD_RESOURCE]: undefined,
  });

  const { data } = await api.create(partialUrl, formData);
  return data;
}
