var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-section", {
    attrs: { status: _vm.sectionStatus, "is-loading": _vm.isLoading },
    scopedSlots: _vm._u([
      {
        key: "header-right",
        fn: function () {
          return [
            _c(
              "div",
              [
                _c("sun-date-picker-v2", {
                  ref: "datePicker",
                  attrs: {
                    id: "analytics-filter-date-range",
                    value: _vm.quickFiltersDate,
                    "max-date": _vm.limitMaxDate,
                    "no-header": "",
                    "no-calendar-icon": "",
                    range: true,
                    "show-date-list": "",
                    "date-list": _vm.dateList,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.onQuickFiltersDate($event.value)
                    },
                    "select:date-list-option": _vm.selectDateOption,
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass:
                  "flex flex-col items-end justify-end w-full p-2 rounded-t-md bg-white lg:flex-row lg:items-center",
              },
              [
                _c("div", { staticClass: "flex justify-between w-full" }, [
                  _c(
                    "div",
                    { staticClass: "mb-2 lg:mb-0 flex items-center" },
                    [
                      _c("upload-button", {
                        staticClass: "w-full text-xs custom-p-1 px-1 sm:w-auto",
                        attrs: { id: "upload-report-modal", variant: "pill" },
                        on: { click: _vm.openUploadReportModal },
                        scopedSlots: _vm._u([
                          {
                            key: "text",
                            fn: function () {
                              return [_vm._v("Upload Report")]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "h-10 mb-2 breakdown lg:mb-0 flex items-center",
                    },
                    [
                      _c("breakdown-wrapper", {
                        attrs: {
                          label: "Select Breakdowns",
                          items: _vm.breakdownOptions,
                          "hide-secondary": "",
                          "horizontal-grow": "",
                          disabled: _vm.isLoading,
                        },
                        on: {
                          input: _vm.onBreakdownHandler,
                          reset: _vm.resetBreakdown,
                        },
                        model: {
                          value: _vm.breakdown,
                          callback: function ($$v) {
                            _vm.breakdown = $$v
                          },
                          expression: "breakdown",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "flex flex-row items-center mb-2 ml-5 lg:mb-0",
                  },
                  [
                    _c(
                      "asterix-dropdown",
                      {
                        attrs: {
                          "button-classes":
                            "flex hover:bg-gray-300 focus:bg-gray-800 text-gray-700 focus:text-gray-200 rounded h-10 px-4 items-center",
                          disabled: _vm.isLoading,
                          options: _vm.reportOptions,
                        },
                        on: { change: _vm.handlerReport },
                        scopedSlots: _vm._u([
                          {
                            key: "dropdown-header",
                            fn: function () {
                              return [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "p-2 pt-0 text-sm font-bold text-left whitespace-no-wrap",
                                  },
                                  [_vm._v("Download as")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "icon",
                            fn: function (state) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "transition-100",
                                    class: !state.visible ? "rotate-180" : "",
                                  },
                                  [
                                    _c("up-svg", {
                                      staticClass: "w-2 h-2",
                                      class: state.disabled
                                        ? "text-gray-400"
                                        : "text-gray-500",
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "block w-4 mr-2" },
                          [_c("download-svg")],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
            !_vm.hasSetting
              ? _c(
                  "div",
                  { staticClass: "flex flex-col w-full mb-8" },
                  [_c("table-loader")],
                  1
                )
              : _vm._e(),
            _vm.hasSetting
              ? _c(
                  "div",
                  { staticClass: "flex flex-col w-full mb-8" },
                  [
                    _c("ag-grid-vue", {
                      key: "TP_table",
                      staticClass: "ag-theme-alpine",
                      staticStyle: { height: "500px" },
                      attrs: {
                        pagination: "",
                        "animate-rows": "",
                        "row-group-panel-show": "",
                        "suppress-agg-func-in-header": "",
                        "get-row-id": _vm.getRowId,
                        "column-defs": _vm.tableColumnsDefinition,
                        "column-types": _vm.columnTypes,
                        "auto-group-column-def": _vm.autoGroupColumnDef,
                        "scrollbar-width": 10,
                        "default-col-def": _vm.defaultColDef,
                        "row-class-rules": _vm.rowClassRules,
                        "tooltip-show-delay": 100,
                        "tree-data": "",
                        "group-agg-filtering": "",
                        "row-model-type": "serverSide",
                        "row-selection": "single",
                        "cache-block-size": 10,
                        "is-server-side-group": _vm.isServerSideGroup,
                        "is-server-side-group-open-by-default":
                          _vm.isServerSideGroupOpenByDefault,
                        "get-server-side-group-key": _vm.getServerSideGroupKey,
                        "grid-options": _vm.gridOptions,
                      },
                      on: {
                        "grid-ready": _vm.onGridReady,
                        "column-moved": _vm.onMovingChanged,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "mb-8" },
              [
                _vm.totals === null
                  ? _c("analytics-total-loader", {
                      attrs: { columns: _vm.totalColumnsNames },
                    })
                  : _c(
                      "box",
                      [
                        _vm._l(_vm.totalColumns, function (col, index) {
                          return [
                            col.value === "netRevenue"
                              ? _c("box-column", {
                                  key: `total-box-${index}`,
                                  attrs: {
                                    title: col.name,
                                    value: _vm._f("currencyEurFilter")(
                                      _vm.totals[col.value],
                                      _vm.notAvailable,
                                      "es-es"
                                    ),
                                  },
                                })
                              : _c("box-column", {
                                  key: `total-box-${index}`,
                                  attrs: {
                                    title: col.name,
                                    value: _vm._f("valueToLocalString")(
                                      _vm.totals[col.value]
                                    ),
                                  },
                                }),
                          ]
                        }),
                      ],
                      2
                    ),
              ],
              1
            ),
            _vm.showUploadReportModal
              ? _c("upload-report-modal", {
                  on: { close: _vm.closeUploadReportModal },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }