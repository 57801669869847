/// <reference path="../../../../model/shared/analytics/ReportType.js" />
import { api } from '.';
import { createAnalyticsReport } from '@/services/shared/analytics/createAnalyticsReport';
import formatDateFiltersToUTC from '@/utils/formatDateFiltersToUTC';

/**
 * @param {string} clientId
 * @param {object} config
 * @param {string} config.clientId
 * @param {string} config.format
 * @param {string} config.type
 * @param {string} config.breakdown
 * @returns {Promise.<{id:string}>}
 */

export default function (clientId, config) {
  const { format, type, ...params } = config;

  params.filter = {
    ...params.filter,
    ...formatDateFiltersToUTC(params.filter),
  };

  const payload = {
    format,
    payload: { ...params },
    type,
  };
  return createAnalyticsReport(api, clientId, payload);
}
