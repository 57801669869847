<template>
  <asterix-form-modal
    title="Upload Report"
    modal-class="sm:max-w-6xl"
    @submit="submitForm"
    @keypress.enter="submitForm"
    @cancel="closeModal"
  >
    <template #content>
      <div class="flex item-start py-1">
        <sun-button variant="pill" class="w-full text-xs sm:w-auto" color="blue" @click="downloadTemplate">
          Download template CSV
        </sun-button>
      </div>
      <div class="flex flex-col items-start py-1">
        <span class="w-full text-sm custom-p-1 sm:w-auto font-bold">Obligatory headers</span>
        <p class="py-1 sm:w-auto text-justify">
          <span
            v-for="header in obligatoryHeaders"
            :key="header"
            class="text-xs font-semibold p-1 mr-1 mb-1 leading-tight align-middle inline-flex items-center bg-orange-500 text-white rounded-md"
          >
            {{ header }}
          </span>
        </p>
      </div>
      <div class="flex flex-col items-start py-1">
        <span class="w-full text-sm custom-p-1 sm:w-auto font-bold">Optional headers</span>
        <p class="py-1 sm:w-auto text-justify">
          <span
            v-for="header in optionalHeaders"
            :key="header"
            class="text-xs font-semibold p-1 mr-1 mb-1 leading-tight align-middle inline-flex items-center bg-orange-500 text-white rounded-md"
          >
            {{ header }}
          </span>
        </p>
      </div>
      <div class="flex flex-col items-start py-1 text-sm">
        <span class="font-bold">Rules</span>
        <span class="text-sm">
          The date format should be <span class="font-bold">Y-m-d</span> or <span class="font-bold">d/m/Y</span>, for
          example: <span class="font-bold">{{ dateToStringByFormat('YYYY-MM-DD', currentDate) }}</span> or
          <span class="font-bold">{{ dateToStringByFormat('DD/MM/YYYY', currentDate) }}</span
          >.
        </span>
        <span class="text-sm">The date cannot be greater than or equal to today.</span>
        <span class="text-sm">
          The metrics leads, visits and sessions are exclusive and cannot be selected together.
        </span>
      </div>
      <div class="overflow-visible pb-2 py-1">
        <form-row>
          <sun-label-group class="w-full" text="Choose your CSV">
            <sun-file-uploader accept=".csv,text/csv" :max-size="104857600" required="required" @change="setFile" />
          </sun-label-group>
        </form-row>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-wrap justify-between sm:px-3">
        <upload-button
          id="download-report-submit"
          :loading="isLoading"
          :disabled="isLoading"
          variant="pill"
          class="w-full text-sm custom-p-1 sm:w-auto"
        >
          <template #text>Upload</template>
        </upload-button>
        <sun-button
          :disabled="isLoading"
          variant="pill"
          class="w-full text-sm custom-p-1 sm:w-auto"
          color="white"
          @click="closeModal"
        >
          Cancel
        </sun-button>
      </div>
    </template>
  </asterix-form-modal>
</template>

<script>
import { mapActions } from 'vuex';
import FormRow from '@/components/atoms/FormRow/FormRow.vue';
import AsterixFormModal from '@/components/organisms/shared/AsterixFormModal.vue';
import UploadButton from '@/components/atoms/UploadButton.vue';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { uploadReport, uploadReportTemplate } from '@/services/modules/ThirdParty/analytics';
import CONFIG from '@/components/organisms/modules/thirdParty/analytics/Modals/config';
import { TODAY } from '@/utils/dateTime/today';
import { dateToStringByFormat } from '@/utils/dateTime/date';

export default {
  name: 'UploadReportModal',
  components: {
    UploadButton,
    AsterixFormModal,
    FormRow,
  },
  data() {
    return {
      isLoading: false,
      file: null,
      currentDate: TODAY,
      obligatoryHeaders: CONFIG.obligatoryHeaders,
      optionalHeaders: CONFIG.optionalsHeaders,
    };
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    dateToStringByFormat,
    closeModal() {
      this.$emit('close');
    },
    async submitForm({ valid }) {
      if (!valid) return;

      if (!this.file) {
        this.createToast(Toast.warning('File not found', 'Please upload at least one report!'));
        return;
      }

      this.isLoading = true;
      try {
        const formData = new FormData();
        formData.set('file', this.file);
        const response = await uploadReport(formData);
        this.createToast(Toast.success('Upload Successful', response.message));
      } catch (error) {
        if (error.status === 400) {
          this.createToast(Toast.warning('Upload failed', error.message));
          this.isLoading = false;
          return;
        }

        this.createToast(Toast.error('Oops! Something went wrong', 'Please contact support!'));
      }

      this.isLoading = false;
      this.closeModal();
    },
    setFile(files) {
      this.file = files[0];
    },
    async downloadTemplate() {
      try {
        const blob = await uploadReportTemplate();
        this.downloadXlsx(blob, 'uploadReportTemplate');
        this.createToast(Toast.info('Upload Report', 'Template downloaded'));
      } catch (error) {
        this.createToast(Toast.error('Oops! Something went wrong', error.message));
      }
    },
    downloadXlsx(blob, nameFile) {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${nameFile}.csv`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    },
  },
};
</script>

<style scoped></style>
