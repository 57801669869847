<template>
  <asterix-section :status="sectionStatus" :is-loading="isLoading">
    <template #header-right>
      <div>
        <sun-date-picker-v2
          id="analytics-filter-date-range"
          ref="datePicker"
          :value="quickFiltersDate"
          :max-date="limitMaxDate"
          no-header
          no-calendar-icon
          :range="true"
          show-date-list
          :date-list="dateList"
          @change="onQuickFiltersDate($event.value)"
          @select:date-list-option="selectDateOption"
        />
      </div>
    </template>
    <template #content>
      <div class="flex flex-col items-end justify-end w-full p-2 rounded-t-md bg-white lg:flex-row lg:items-center">
        <div class="flex justify-between w-full">
          <div class="mb-2 lg:mb-0 flex items-center">
            <upload-button
              id="upload-report-modal"
              variant="pill"
              class="w-full text-xs custom-p-1 px-1 sm:w-auto"
              @click="openUploadReportModal"
            >
              <template #text>Upload Report</template>
            </upload-button>
          </div>
          <div class="h-10 mb-2 breakdown lg:mb-0 flex items-center">
            <breakdown-wrapper
              v-model="breakdown"
              label="Select Breakdowns"
              :items="breakdownOptions"
              hide-secondary
              horizontal-grow
              :disabled="isLoading"
              @input="onBreakdownHandler"
              @reset="resetBreakdown"
            />
          </div>
        </div>
        <div class="flex flex-row items-center mb-2 ml-5 lg:mb-0">
          <asterix-dropdown
            button-classes="flex hover:bg-gray-300 focus:bg-gray-800 text-gray-700 focus:text-gray-200 rounded h-10 px-4 items-center"
            :disabled="isLoading"
            :options="reportOptions"
            @change="handlerReport"
          >
            <span class="block w-4 mr-2">
              <download-svg />
            </span>
            <template #dropdown-header>
              <p class="p-2 pt-0 text-sm font-bold text-left whitespace-no-wrap">Download as</p>
            </template>
            <template #icon="state">
              <span class="transition-100" :class="!state.visible ? 'rotate-180' : ''">
                <up-svg class="w-2 h-2" :class="state.disabled ? 'text-gray-400' : 'text-gray-500'" />
              </span>
            </template>
          </asterix-dropdown>
        </div>
      </div>
      <div v-if="!hasSetting" class="flex flex-col w-full mb-8">
        <table-loader />
      </div>
      <div v-if="hasSetting" class="flex flex-col w-full mb-8">
        <ag-grid-vue
          key="TP_table"
          style="height: 500px"
          class="ag-theme-alpine"
          pagination
          animate-rows
          row-group-panel-show
          suppress-agg-func-in-header
          :get-row-id="getRowId"
          :column-defs="tableColumnsDefinition"
          :column-types="columnTypes"
          :auto-group-column-def="autoGroupColumnDef"
          :scrollbar-width="10"
          :default-col-def="defaultColDef"
          :row-class-rules="rowClassRules"
          :tooltip-show-delay="100"
          tree-data
          group-agg-filtering
          row-model-type="serverSide"
          row-selection="single"
          :cache-block-size="10"
          :is-server-side-group="isServerSideGroup"
          :is-server-side-group-open-by-default="isServerSideGroupOpenByDefault"
          :get-server-side-group-key="getServerSideGroupKey"
          :grid-options="gridOptions"
          @grid-ready="onGridReady"
          @column-moved="onMovingChanged"
        />
      </div>
      <div class="mb-8">
        <analytics-total-loader v-if="totals === null" :columns="totalColumnsNames" />
        <box v-else>
          <template v-for="(col, index) in totalColumns">
            <box-column
              v-if="col.value === 'netRevenue'"
              :key="`total-box-${index}`"
              :title="col.name"
              :value="totals[col.value] | currencyEurFilter(notAvailable, 'es-es')"
            />
            <box-column
              v-else
              :key="`total-box-${index}`"
              :title="col.name"
              :value="totals[col.value] | valueToLocalString"
            />
          </template>
        </box>
      </div>
      <upload-report-modal v-if="showUploadReportModal" @close="closeUploadReportModal" />
    </template>
  </asterix-section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { AgGridVue } from 'ag-grid-vue';
import AsterixSection from '@/components/templates/AsterixSection.vue';
import CONFIG_V2, {
  agGridColumns,
  agGridSecondaryColumns,
} from '@/views/private/modules/thirdParty/analytics/config_v2';
import { dateToYYYYMMDD, stringToDate } from '@/filters/dateFilters';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import filtersMixin from '@/mixins/filters/filtersMixin';
import { indexMixin } from '@/mixins/index/indexMixin';
import metaInfo from '@/mixins/common/metaInfo';
import AsterixDropdown from '@/components/molecules/shared/AsterixDropdown.vue';
import BreakdownWrapper from '@/components/molecules/modules/Analytics/Breakdown/BreakdownWrapper.vue';
import DownloadSvg from '@/components/icons/DownloadSvg.vue';
import UpSvg from '@/components/icons/UpSvg.vue';
import Breakdown from '@/model/shared/Breakdown';
import { BREAKDOWN_VALUES } from '@/model/modules/thirdParty/analytics/BreakdownValues';
import { deepClone } from '@/utils/deepClone';
import AnalyticsTotalLoader from '@/components/atoms/Loaders/AnalyticsTotalLoader';
import Box from '@/components/atoms/Box/Box';
import BoxColumn from '@/components/atoms/Box/BoxColumn';
import { BREAKDOWN } from '@/store/modules/filters/keys';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { getAnalyticsV2 } from '@/services/modules/ThirdParty/analytics';
import { SECTION_STATUS } from '@/model/shared/sectionStatus';
import AnalyticsCellOrchestrator from '@/components/organisms/modules/thirdParty/AnalyticsCellOrchestrator';
import agTableUtils from '@/mixins/agGrid/agTableUtils';
import currency from '@/filters/currency';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';
import { USER } from '@/store/modules/auth/keys';
import { GET_USER_SETTINGS_REQUEST, UPDATE_USER_SETTINGS_REQUEST, USER_SETTINGS } from '@/store/modules/settings/keys';
import AVAILABLE_SETTINGS from '@/model/shared/user/availableSettings';
import TableLoader from '@/components/atoms/Loaders/TableLoader.vue';
import SunDatePickerV2 from '@/components/atoms/SunDatePickerV2/SunDatePickerV2.vue';
import UploadButton from '@/components/atoms/UploadButton.vue';
import UploadReportModal from '@/components/organisms/modules/thirdParty/analytics/Modals/UploadReportModal.vue';

export default {
  name: 'AnalyticsListV2',
  components: {
    UploadReportModal,
    UploadButton,
    SunDatePickerV2,
    UpSvg,
    TableLoader,
    AgGridVue,
    DownloadSvg,
    BreakdownWrapper,
    AsterixDropdown,
    AsterixSection,
    AnalyticsTotalLoader,
    Box,
    BoxColumn,
    // eslint-disable-next-line vue/no-unused-components
    AnalyticsCellOrchestrator,
  },
  filters: {
    valueToLocalString: value => {
      if (value === null || value === undefined) return 'N/A';
      if (value % 1 === 0) {
        return thousandSeparator(value.toLocaleString('es-ES'));
      }
      return thousandSeparator(
        value.toLocaleString('es-ES', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 })
      );
    },
    currencyEurFilter: value => {
      if (value === null || value === undefined) return 'N/A';
      return thousandSeparator(currency(value, undefined, 'es-es', 2, 'N/A'));
    },
  },
  mixins: [
    activeClientMixin,
    filtersMixin({
      filters: [],
      filterQuick: {
        name: undefined,
        range_start: undefined,
        range_end: undefined,
      },
    }),
    indexMixin,
    metaInfo,
    agTableUtils,
  ],
  props: {
    analyticType: {
      type: String,
      default: () => null,
    },
  },
  data: () => ({
    // totals
    totals: null,
    totalColumns: CONFIG_V2.totalColumns,
    // sort
    sort: null,
    // date
    dateList: CONFIG_V2.dateList,
    defaultDate: CONFIG_V2.defaultDate,
    limitMaxDate: CONFIG_V2.limitMaxDate,
    datepickerKey: 0,
    // breakdown
    breakdownOptions: CONFIG_V2.breakdown,
    breakdown: new Breakdown(BREAKDOWN_VALUES.product),
    previousBreakdown: new Breakdown(BREAKDOWN_VALUES.product),
    // loading
    isLoading: false,
    // ag-grid
    tableKey: 0,
    gridApi: null,
    gridColumnsApi: null,
    primaryRowKeys: new Set(),
    secondaryRowKeys: new Set(),
    primaryRowCache: {},
    secondaryRowCache: {},
    gridOptions: CONFIG_V2.gridOptions,
    defaultColDef: CONFIG_V2.defaultColDef,
    rowClassRules: CONFIG_V2.rowClassRules,
    columnTypes: CONFIG_V2.columnTypes,
    exportParams: CONFIG_V2.exportParams,
    rowData: [],
    // report
    reportOptions: [
      { id: 'CSV', name: 'CSV', value: 'CSV' },
      { id: 'XLSX', name: 'XLSX', value: 'XLSX' },
    ],
    notAvailable: 'N/A',
    rowThirdCache: [],
    updateUserSettingsTimeout: null,
    settings: null,
    isLoadingSettings: false,
    showUploadReportModal: false,
  }),
  computed: {
    ...mapGetters({
      user: USER,
      userSettings: USER_SETTINGS,
      getStoredBreakdown: BREAKDOWN,
      // reportToast: ANALYTICS_REPORT_TOAST,
    }),
    commonIdView() {
      return `${CONFIG_V2.COMMON_ID_VIEW}_SHARED`;
    },
    // date calendar computed -----------------------------------
    quickFiltersDate() {
      const rangeStart = this.filterQuick.range_start;
      const rangeEnd = this.filterQuick.range_end;

      const startDate = rangeStart ? stringToDate(rangeStart) : this.defaultDate.startDate;
      const endDate = rangeEnd ? stringToDate(rangeEnd) : this.defaultDate.endDate;
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 0);

      return { startDate, endDate };
    },
    // breakdown computed -----------------------------------
    storedBreakdown() {
      return this.getStoredBreakdown(this.$route.name);
    },
    breakdownPrimaryCapitalized() {
      const firstL = this.breakdown.primary.charAt(0);
      const lastWord = this.breakdown.primary.slice(1);

      return firstL.toUpperCase() + lastWord;
    },
    breakdownSecondaryCapitalized() {
      if (!this.breakdown.secondary) return null;
      const firstL = this.breakdown.secondary.charAt(0);
      const lastWord = this.breakdown.secondary.slice(1);

      return firstL.toUpperCase() + lastWord;
    },
    hasSecondaryBreakdown() {
      return !!this.breakdown.secondary;
    },
    hasThirdBreakdown() {
      return (
        this.breakdown.primary === BREAKDOWN_VALUES.campaign && this.breakdown.secondary === BREAKDOWN_VALUES.product
      );
    },
    // Totals computed ---------------------------------------
    totalColumnsNames() {
      return CONFIG_V2.totalColumns.map(col => col.name);
    },
    hasSetting() {
      return (
        !!this.userSettings &&
        !!this.userSettings?.hasTableByContext(AVAILABLE_SETTINGS.ANALYTICS_DIRECT, this.analyticType)
      );
    },
    // Ag-grid Computed -------------------------------------
    availableColumnsByBreakdown() {
      const columns = agGridColumns
        .concat(this.secondaryBreakdownCol || [])
        .concat(this.thirdBreakdownCol)
        .filter(header => !header.rolesAllowed.length || header.rolesAllowed.includes(this.contextUserRole))
        .filter(header => !header.breakdowns.length || header.breakdowns.includes(this.breakdown.primary));

      if (this.settings?.hasTableByContext(AVAILABLE_SETTINGS.ANALYTICS_DIRECT, this.analyticType)) {
        const orderTable = this.settings.getTableByContext(AVAILABLE_SETTINGS.ANALYTICS_DIRECT, this.analyticType);
        columns.sort((a, b) => {
          const indexA = orderTable.indexOf(a.field);
          const indexB = orderTable.indexOf(b.field);

          return indexA - indexB;
        });
      }

      return columns;
    },
    tableColumnsDefinition() {
      return [...this.availableColumnsByBreakdown].map(
        ({
          headerName,
          field,
          headerClass,
          cellClassRules,
          visible,
          maxWidth,
          width,
          filter,
          filterParams,
          type,
          pinned,
          sort,
          suppressSizeToFit,
          suppressAutoSize,
          comparator,
          suppressMovable,
        }) => {
          const headName = this.transformHeaderNamebyBreakdown(field, headerName);
          const hasGroup = this.isGroupColumn(field);
          const isSecondary = this.isSecondaryGroupColumn(field);
          const isThird = this.isThirdGroupColumn(field);
          return {
            headerName: headName,
            field,
            hide: field === 'breakdown' || hasGroup || isSecondary || isThird ? true : !visible,
            headerClass,
            cellClassRules,
            maxWidth,
            minWidth: 50,
            width,
            pinned,
            filter,
            filterParams,
            type,
            sort,
            suppressSizeToFit,
            suppressAutoSize,
            enableValue: isSecondary || isThird,
            comparator,
            aggFunc: this.customAggFunc,
            headerTooltip: headerName,
            suppressMovable,
          };
        }
      );
    },
    primaryBreakdownCol() {
      const row = this.availableColumnsByBreakdown.find(header => header.value === 'breakdown');
      row.headerName = this.transformHeaderNamebyBreakdown(row.field, row.headerName);
      return row;
    },
    secondaryBreakdownCol() {
      return agGridSecondaryColumns.find(col => col.value === this.breakdown.secondary);
    },
    thirdBreakdownCol() {
      if (!this.hasThirdBreakdown) return [];

      return agGridSecondaryColumns.find(col => col.value === BREAKDOWN_VALUES.date);
    },
    autoGroupColumnDef() {
      let headerName = this.primaryBreakdownCol.headerName;

      if (this.secondaryBreakdownCol?.headerName) {
        headerName += ` / ${this.secondaryBreakdownCol?.headerName}`;
      }

      return {
        headerName,
        width: 50,
        minWidth: 50,
        pinned: 'left',
        suppressSizeToFit: true,
        suppressAutoSize: true,
        suppressMovable: true,
        type: 'textColumn',
        // field: this.secondaryBreakdownCol ? this.secondaryBreakdownCol.field : null,
        // For server-side-model-tree-data should set field unique in this case is the BREAKDOWN
        field: 'breakdown',
        filterValueGetter: params => {
          if (params.data.level === 0) {
            return params.node.parent.data[this.primaryBreakdownCol.field];
          }

          if (params.data.level === 1) {
            return params.node.parent.parent.data[this.primaryBreakdownCol.field];
          }

          return params.data[this.primaryBreakdownCol.field];
        },
        cellRendererParams: {
          innerRenderer: params => {
            return params.data[this.primaryBreakdownCol.field];
          },
        },
        headerTooltip: headerName,
        tooltipValueGetter: params => {
          return params.value;
        },
      };
    },
  },
  watch: {
    autoGroupColumnDef(newVal) {
      this.gridApi?.setAutoGroupColumnDef(newVal);
    },
    userSettings: {
      deep: true,
      handler(newSettings) {
        this.setSettings(newSettings);
      },
    },
  },
  created() {
    window.addEventListener('resize', this.resizeColumns);
    this.setBreakdown();
    this.eventOrder(this.initialSort);
  },
  async mounted() {
    await this.setSettings(this.userSettings);
    await this.filtersLoadAllfiltersOnMounted(this.commonIdView);
    this.setDefaultDateFilter();
    // this.sort = this.sortByDefault;
    // await this.updateSortInUrl();
    await this.filtersUpdateFiltersOnStoreAndURL(this.commonIdView);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeColumns);
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
      getUserSettings: GET_USER_SETTINGS_REQUEST,
      updateUserSettings: UPDATE_USER_SETTINGS_REQUEST,
    }),
    // Date calendar methods --------------------------------
    async onQuickFiltersDate(value) {
      this.setFilterQuick(value || this.defaultDate);
      await this.changeFilters(this.filterFind);
    },
    setDefaultDateFilter() {
      this.setFilterQuick(this.quickFiltersDate);
    },
    selectDateOption(option) {
      if (option === CONFIG_V2.CUSTOM_OPTION) {
        setTimeout(() => (this.$refs.datePicker.$refs.menu.isMenuActive = true), 0);
      }
    },
    setFilterQuick({ startDate, endDate }) {
      endDate.setHours(23, 59, 59, 0);
      startDate.setHours(0, 0, 0, 0);
      this.filterQuick.range_start = startDate.toISOString();
      this.filterQuick.range_end = endDate.toISOString();

      const { startDateCompare, endDateCompare } = this.getDateCompare(startDate, endDate);
      this.filterQuick.compare_range_start = startDateCompare.toISOString();
      this.filterQuick.compare_range_end = endDateCompare.toISOString();

      // TODO Is this necessary?
      this.datepickerKey++;
    },
    getDateCompare(startDate, endDate) {
      const MILLISECONDS_SECOND = 1000;
      const SECONDS_HOUR = 3600;
      const HOURS_DAY = 24;

      const difference = Math.round((endDate - startDate) / MILLISECONDS_SECOND / SECONDS_HOUR / HOURS_DAY);
      const startDateCompare = stringToDate(startDate);
      const endDateCompare = stringToDate(endDate);

      startDateCompare.setDate(startDateCompare.getDate() - difference);
      endDateCompare.setDate(endDateCompare.getDate() - difference);

      return { startDateCompare, endDateCompare };
    },
    // filters methods --------------------------------
    resetFilters() {
      this.gridApi?.setFilterModel(null);
    },
    async updateFilters(filters = []) {
      this.replaceQueryParams({ page: 1, breakdown: this.queryParams.breakdown || null });

      this.filterFind = filters;
      await this.filtersUpdateFiltersOnStoreAndURL(this.commonIdView);
    },
    async changeFilters(filters) {
      this.resetCache();
      this.rowData = [];
      this.gridApi.refreshServerSideStore({ purge: true });
      await this.updateFilters(filters);
    },
    resetCache() {
      this.secondaryRowKeys.clear();
      this.primaryRowKeys.clear();

      this.primaryRowCache = {};
      this.secondaryRowCache = {};
    },

    // breakdown methods --------------------------------
    resetBreakdown() {
      this.breakdown = new Breakdown(BREAKDOWN_VALUES.product);
    },

    onBreakdownHandler() {
      if (this.previousBreakdown === this.breakdown) return null;
      if (this.previousBreakdown.primary !== this.breakdown.primary) {
        this.rowData = [];
        this.resetCache();
      } else if (this.previousBreakdown.secondary !== this.breakdown.secondary) {
        this.gridApi.collapseAll();
        this.resetCache();
      }
      this.previousBreakdown = deepClone(this.breakdown);
      this.resetFilters();
    },

    setBreakdown() {
      const retrievedPrimary = this.queryParams.breakdown || this.storedBreakdown?.primary || this.breakdown.primary;
      const retrievedSecondary =
        this.queryParams.subBreakdown || this.storedBreakdown?.secondary || this.breakdown.secondary;

      this.breakdown.primary = retrievedPrimary;
      this.breakdown.secondary = retrievedSecondary;
    },
    // reports methods -----------------------------------------
    handlerReport({ value }) {
      const params = deepClone(this.exportParams);
      const date = dateToYYYYMMDD(new Date());
      if (value === 'CSV') {
        params.fileName = `${date}-direct-analytics.csv`;
        this.gridApi.exportDataAsCsv(params);
      } else if (value === 'XLSX') {
        params.fileName = `${date}-direct-analytics.xlsx`;
        this.gridApi.exportDataAsExcel(params);
      }
    },
    // AG GRID methods ------------------------------------------
    onGridReady({ api, columnApi }) {
      this.gridApi = api;
      this.gridColumnApi = columnApi;
      this.gridApi.setServerSideDatasource(this.getDataSource());
    },
    // Se llama a getRows cuando desplegamos alguna agrupacion
    getDataSource() {
      return {
        getRows: async params => {
          let data;
          let response;
          const firstId = params.parentNode?.id?.split('-')[0];
          const id = params.parentNode?.id?.replace(`${firstId}-`, '');
          switch (params.parentNode.level) {
            case 0:
              if (this.primaryRowKeys.has(firstId)) {
                response = this.primaryRowCache[`${firstId}`];
              } else {
                data = await this.getSecondLevelItems(params.parentNode.id);
                response = data.map(row => {
                  return {
                    [this.breakdown.secondary]: row.breakdown,
                    level: 0,
                    ...row,
                  };
                });

                this.primaryRowKeys.add(firstId);
                this.primaryRowCache = {
                  [`${firstId}`]: response,
                  ...this.primaryRowCache,
                };
              }

              params.success({ rowData: response });
              break;
            case 1:
              if (this.secondaryRowKeys.has(id)) {
                response = this.secondaryRowCache[`${id}`];
              } else {
                data = await this.getThirdLevelItems(params.parentNode.id);
                response = data.map(row => {
                  return {
                    [this.breakdown.secondary]: row.breakdown,
                    level: 1,
                    ...row,
                  };
                });
                this.secondaryRowKeys.add(id);
                this.secondaryRowCache = {
                  [`${id}`]: response,
                  ...this.secondaryRowCache,
                };
              }

              params.success({ rowData: response });
              break;
            case -1:
            default:
              if (!this.rowData.length) {
                await this.getFirstLevelItems();
              }
              if (this.rowData) {
                params.success({ rowData: this.rowData });
              }
              break;
          }
        },
      };
    },
    isServerSideGroup(dataItem) {
      return (dataItem.level === -1 && this.hasSecondaryBreakdown) || (dataItem.level === 0 && this.hasThirdBreakdown);
    },
    isServerSideGroupOpenByDefault(params) {
      return (
        (params.rowNode.level === 0 && this.primaryRowKeys.has(params.data.id)) ||
        (params.rowNode.level === 1 && this.secondaryRowKeys.has(params.data.id))
      );
    },
    getServerSideGroupKey(dataItem) {
      return dataItem.id;
    },
    getRowId(row) {
      return `${row.data.id}`;
    },
    // Esta funcion decide cual de las columnas debe de ser un grupo
    isGroupColumn(field) {
      if (!this.hasSecondaryBreakdown) return false;
      return field === 'breakdown' || this.isGroupThirdLevel(field);
    },
    isGroupThirdLevel(field) {
      if (!this.hasSecondaryBreakdown) return false;
      return !!(
        this.breakdown.primary === BREAKDOWN_VALUES.campaign &&
        this.breakdown.secondary === BREAKDOWN_VALUES.product &&
        field === BREAKDOWN_VALUES.product
      );
    },
    isSecondaryGroupColumn(field) {
      if (!this.hasSecondaryBreakdown) return false;

      return (
        (this.breakdown.secondary === BREAKDOWN_VALUES.browser && field === 'Browser') ||
        (this.breakdown.secondary === BREAKDOWN_VALUES.country && field === 'Country') ||
        (this.breakdown.secondary === BREAKDOWN_VALUES.date && field === 'Date') ||
        (this.breakdown.secondary === BREAKDOWN_VALUES.platform && field === 'platform') ||
        (this.breakdown.secondary === BREAKDOWN_VALUES.device && field === 'Device') ||
        (this.breakdown.secondary === BREAKDOWN_VALUES.product && field === 'campaign') ||
        (this.breakdown.secondary === BREAKDOWN_VALUES.campaign && field === 'product')
      );
    },
    isThirdGroupColumn(field) {
      if (!this.hasSecondaryBreakdown) return false;
      return !!(this.hasThirdBreakdown && field === 'Date');
    },
    transformHeaderNamebyBreakdown(field, headerName) {
      if (field === 'breakdown') {
        switch (this.breakdown.primary) {
          case BREAKDOWN_VALUES.product:
            return 'PRODUCT NAME';
          case BREAKDOWN_VALUES.campaign:
            return 'CAMPAIGN NAME';
          case BREAKDOWN_VALUES.platform:
            return 'PLATFORM NAME';

          default:
            return headerName;
        }
      }
      return headerName;
    },
    resizeColumns() {
      if (this.gridApi) {
        setTimeout(() => {
          this.gridApi.sizeColumnsToFit();
        });
      }
    },
    // Data methods ----------------------------------------------------
    async getFirstLevelItems() {
      try {
        this.isLoading = true;
        this.resetFilters();
        // this.gridApi?.hideOverlay();
        // this.gridApi?.showLoadingOverlay();
        const auxParams = this.setParamsToTable();
        auxParams.page = this.currentPage;
        auxParams.itemsPerPage = 2000;
        auxParams.addFilter('breakdown', this.breakdownPrimaryCapitalized);
        // TODO poner el filtro de la vista correspondiente
        auxParams.addFilter('analytic.type', this.analyticType);
        auxParams.removeFilter('compare_range_start');
        auxParams.removeFilter('compare_range_end');
        const { data, metrics } = await getAnalyticsV2(this.activeClientId, auxParams);
        this.rowData = data;
        this.totals = metrics;
        // force level value
        this.rowData = this.rowData.map(element => ({ level: -1, ...element }));
        // this.gridApi?.hideOverlay();
        this.tableKey++;
      } catch (error) {
        if (error.code !== HTTPStatusCode.Cancel) {
          this.sectionStatus = SECTION_STATUS.ERROR;
          await this.createToast(Toast.error(`We can not get analytic`, error));
        }
      }
      this.isLoading = false;
    },
    async getSecondLevelItems(itemId) {
      this.isLoading = true;
      const auxParams = this.setParamsToTable();
      auxParams.itemsPerPage = 2000;
      auxParams.removeFilter('compare_range_start');
      auxParams.removeFilter('compare_range_end');
      auxParams.addFilter('analytic.type', this.analyticType);
      auxParams.addFilter('breakdown', this.breakdownPrimaryCapitalized);
      auxParams.addFilter('subBreakdown', this.breakdownSecondaryCapitalized);
      if (this.breakdown.primary === BREAKDOWN_VALUES.campaign) {
        auxParams.addFilter('campaign.id', itemId);
      } else if (this.breakdown.primary === BREAKDOWN_VALUES.product) {
        auxParams.addFilter('product.id', itemId);
      } else if (this.breakdown.primary === BREAKDOWN_VALUES.platform) {
        auxParams.addFilter('platform.id', itemId);
      }
      try {
        const { data } = await getAnalyticsV2(this.activeClientId, auxParams);
        this.isLoading = false;
        return data;
      } catch (error) {
        if (error.code !== HTTPStatusCode.Cancel) {
          this.sectionStatus = SECTION_STATUS.ERROR;
          await this.createToast(Toast.error(`We can not get analytic`, error));
        }
      }
      this.isLoading = false;
    },
    async getThirdLevelItems(itemId) {
      this.isLoading = true;
      const auxParams = this.setParamsToTable();
      auxParams.itemsPerPage = 2000;
      auxParams.removeFilter('compare_range_start');
      auxParams.removeFilter('compare_range_end');
      auxParams.addFilter('analytic.type', this.analyticType);
      auxParams.addFilter('breakdown', 'Product');
      auxParams.addFilter('subBreakdown', 'Date');
      auxParams.addFilter('product.id', itemId);
      try {
        const { data } = await getAnalyticsV2(this.activeClientId, auxParams);
        this.isLoading = false;
        return data;
      } catch (error) {
        if (error.code !== HTTPStatusCode.Cancel) {
          this.sectionStatus = SECTION_STATUS.ERROR;
          await this.createToast(Toast.error(`We can not get analytic`, error));
        }
      }
      this.isLoading = false;
    },
    onMovingChanged() {
      const newOrder = this.gridColumnApi
        .getAllGridColumns()
        .filter(col => !col.colDef.hide)
        .map(col => col.colDef);

      this.settings.setTableByContext(AVAILABLE_SETTINGS.ANALYTICS_DIRECT, this.analyticType, newOrder);
      if (this.updateUserSettingsTimeout) {
        clearTimeout(this.updateUserSettingsTimeout);
        this.updateUserSettingsTimeout = null;
      }

      this.updateUserSettingsTimeout = setTimeout(() => {
        this.updateUserSettings(this.settings);
      }, 2000);
    },
    async setSettings(settings) {
      if (!settings && !this.hasSetting && !this.isLoadingSettings) {
        await this.loadSettings();
      }
      if (settings && this.hasSetting && !this.settings && !this.isLoadingSettings) {
        this.settings = this.userSettings;
      }
    },
    async loadSettings() {
      this.isLoadingSettings = true;
      const settings = await this.getUserSettings();
      if (!settings.hasTableByContext(AVAILABLE_SETTINGS.ANALYTICS_DIRECT, this.analyticType)) {
        const newSettings = settings.clone();
        await newSettings.setTableByContext(
          AVAILABLE_SETTINGS.ANALYTICS_DIRECT,
          this.analyticType,
          this.availableColumnsByBreakdown
        );
        await this.updateUserSettings(newSettings);
      }

      this.settings = settings;
      this.isLoadingSettings = false;
    },
    openUploadReportModal() {
      this.showUploadReportModal = true;
    },
    closeUploadReportModal() {
      this.showUploadReportModal = false;
    },
  },
};
function thousandSeparator(value) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}
</script>

<style lang="scss" scoped>
@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-alpine.css';

.breakdown {
  min-width: 280px;
}

:deep(.main-headers) {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

:deep(.bg-impressions-red) {
  color: white;
  background-color: red;
}

:deep(.ag-sort-none-icon) {
  margin-left: 0px !important;
}

:deep(.border-r-transparent) {
  border-right-color: transparent;
}

:deep(.date.z-10) {
  @apply z-0;
}

:deep(.ag-theme-alpine .ag-root-wrapper) {
  border: 0;
}

:deep(.ag-theme-alpine .ag-header) {
  @apply bg-gray-300 border-0;
}

:deep(.ag-theme-alpine .ag-header-cell) {
  @apply px-1;
}

:deep(.ag-theme-alpine .ag-header-cell-label),
:deep(.ag-theme-alpine .ag-cell-value) {
  @apply text-xs px-1;
}

:deep(.ag-theme-alpine .ag-floating-filter-button) {
  display: none;
}

:deep(.ag-theme-alpine .ag-watermark) {
  opacity: 0 !important;
  display: none !important;
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
  right: auto !important;
  bottom: auto !important;
  visibility: hidden !important;
  transition: none !important;
}

:deep(.ag-theme-alpine .ag-ltr .ag-row-group-indent-2:not(.ag-cell-expandable)) {
  padding-left: 50px;
}
:deep(.ag-theme-alpine .ag-ltr .ag-row-group-indent-1:not(.ag-cell-expandable)) {
  padding-left: 30px;
}
:deep(.ag-theme-alpine .ag-ltr .ag-row-group-leaf-indent:not(.ag-cell-expandable)) {
  margin-left: 0;
}

:deep(.ag-theme-alpine .ag-row.product-starting) {
  @apply text-green-600 bg-green-100;
}

:deep(.ag-theme-alpine .ag-row.product-tomorrow) {
  @apply text-orange-500 bg-orange-100;
}

:deep(.ag-theme-alpine .ag-row.product-ending) {
  @apply text-red-500 bg-red-100;
}
</style>
