import { api } from '.';
import { ANALYTICS_RESOURCE, CLIENT_RESOURCE, UPLOAD_RESOURCE, PRODUCT_TRAFFIC_RESOURCE } from '@/services/keys';

/**
 *
 * @param {string} clientId
 * @param {HeadersMapper} headersMapper
 * @returns {Promise.<Object>}
 */
export async function uploadCSVByProduct(clientId, headersMapper) {
  const partialUrl = api.createUrl({
    [ANALYTICS_RESOURCE]: undefined,
    [CLIENT_RESOURCE]: clientId,
    [UPLOAD_RESOURCE]: undefined,
    [PRODUCT_TRAFFIC_RESOURCE]: undefined,
  });

  const { data } = await api.create(partialUrl, headersMapper.payload());
  return data;
}
