import { api } from '.';
import getIngestionJobNotifications from '@/services/shared/notifications/getIngestionJobNotifications';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import thirdPartyEntityParser from '@/entities/ThirdParty/ThirdPartyEntityParser';

/**
 * @param {string} clientId
 * @param {QueryParamsBuilder} params
 * @returns {Promise<{data:string}>}
 */
export default async function (clientId, params = new QueryParamsBuilder()) {
  const { data } = await getIngestionJobNotifications(api, clientId, params);
  return new ResponseBuilder(thirdPartyEntityParser.parse(data), data.meta).build();
}
