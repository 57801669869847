import { ANALYTICS_RESOURCE, REPORT_RESOURCE, CLIENT_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';

/**
 * @param {import('@/services/api/Obelix').Obelix} api
 * @param {string} clientId
 * @param {object} payload
 * @returns {Promise<{data:string}>}
 */
export async function createAnalyticsReport(api, clientId, payload) {
  const partialUrl = api.createUrl({
    [ANALYTICS_RESOURCE]: undefined,
    [CLIENT_RESOURCE]: clientId,
    [REPORT_RESOURCE]: undefined,
  });
  const { data } = await api.create(partialUrl, payload);
  return new ResponseBuilder(data.analyticReport).build();
}
