/**
 * Metric
 * @constructor
 * @public
 */
export default class Metric {
  /**
   * @typedef {object} Metric
   * @property @param {number} impressions
   * @property @param {number} clicks
   * @property @param {number} grossRevenue
   * @property @param {number} grossRevenueUsd
   */
  constructor(impressions = null, clicks = null, grossRevenue = null, grossRevenueUsd = null) {
    this.impressions = impressions;
    this.clicks = clicks;
    this.grossRevenue = grossRevenue;
    this.grossRevenueUsd = grossRevenueUsd;
  }

  /**
   * This method is used to get a AnalyticMetric object from a JSON.
   * @param {AnalyticMetric} attr
   * @return {AnalyticMetric}
   */
  static build(attr = {}) {
    return new Metric(attr.impressions, attr.clicks, attr.netRevenue, attr.netRevenueUsd);
  }
}
